import {useContext,useEffect,useRef,useState} from "react";
import Header from "../components/Header";
import { GlobalContextType} from '../@types/user';
import { useNavigate } from "react-router-dom";
import { Button, Input, Spin } from "antd";
import { globalData } from "../context/globalContext";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Axios } from "../Axios";
import AuthenticatedLayout from "../components/layouts/AuthenticatedLayout";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { IconButton, Modal } from "@mui/material";
import { Search } from "@mui/icons-material";
import InputBox from "../components/input";
import ModalBox from "../components/modal";
import TripleDotMenu from "../components/tripledotMenu/TripleDotMenu";
import PassToggle from "../components/passToggle/PassToggle";
import FSLoader from "../components/FSLoader";

const Users = ()=>{

    const navigator = useNavigate();
    const { activeTab, setActiveTab } = useContext(globalData) as GlobalContextType;
    const user = useSelector((state: RootState) => state.user.user );
    const [allowView,setAllowView] = useState<boolean>(true);
    const [loading,setLoading] = useState<boolean>(false)
    const [prevLoad,setPrevLoad] = useState<boolean>(false)
    const [nextLoad,setNextLoad] = useState<boolean>(false)
    const [data,setData] = useState<{id:string,name:string,email:string,password:string,status:number}[]>([]);
    const [next,setNext] = useState<boolean>(false);
    const [prev,setPrev] = useState<boolean>(false);
    const [page,setPage] = useState<number>(1);
    const [search,setSearch] = useState<string>("");
    const [timeLimit, setTimeLimit] = useState<any>();
    const [createUserPopup, setCreateUserPopup] = useState(false);
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error,setError] = useState("");
    const mBtn = useRef<any>();
    const [showMenu, setShowMenu] = useState(false);
    const [mTargetElem,setMtargetElem] = useState<any>();
    const [confirm,setConfirm] = useState({id:"",type:0,msg:""});
    useEffect(()=>{

        ApiCall();

    },[page])

    const ApiCall = ()=>{
        setLoading(true);

        Axios.get(`/api/v1/admin/users?type=1&page=${page}&search=${search ? search : ""}`,{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{
            setAllowView(true);
            console.log("fetched", result);
            let x = result.data.data.users.map((elem:any)=> ({
                id:elem._id || elem.id,
                name: elem.name,
                email: elem.email,
                password: elem.visible_password,
                status: elem.account_status
            }) );

            console.log(x);
            setData(x);
            setLoading(false);
            setPrevLoad(false);
            setNextLoad(false);
            // let x = [...result.data.data.history];
            setNext(result.data.data.next);
            setPrev(result.data.data.prev);
            // setData(x);
            
            
        })
        .catch(err=>{
            setAllowView(true);

            setLoading(false);
            setPrevLoad(false);
            setNextLoad(false);
            
        })
    }

    useEffect(()=>{

        clearTimeout(timeLimit);
        setTimeLimit(setTimeout(()=>{

            ApiCall();

        },500))

    },[search]);

    const createNewUSer = ()=>{

        Axios.post(`/api/v1/admin/users`,{
            name: name,
            email: email,
            password: password
        },{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{
            ApiCall();
            setCreateUserPopup(false);
            setEmail("");
            setPassword("");
            setName("");

        })
        .catch(err=>{
            setError(err.response.data.message);
        })
    }

    const generatePass = ()=>{
        Axios.get(`/api/v1/admin/random-sequence`,{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{

           setPassword(result.data.data);

        })
        .catch(err=>{
            setError(err.response.data.message);
        })
    }


    const revokeAccess = (id:string)=>{

        Axios.put(`/api/v1/admin/users/access/revoke`,{
            id: id
        },{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{
            ApiCall();
            setConfirm({id:"",type:0,msg:""});

        })
        .catch(err=>{
            setError(err.response.data.message);
        })
    }
    const grantAccess = (id:string)=>{

        Axios.put(`/api/v1/admin/users/access/grant`,{
            id: id
        },{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{
            ApiCall();
            setConfirm({id:"",type:0,msg:""});
        })
        .catch(err=>{
            setError(err.response.data.message);
        })
    }

    const shareCredentials = (id:string)=>{

        Axios.post(`/api/v1/admin/users/credential/share/${id}`,{},{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{

            
        })
        .catch(err=>{
            setError(err.response.data.message);
            
        })

        setConfirm({id:"",type:0,msg:""});
    }


    return(
        <>
        <AuthenticatedLayout>
        {
            createUserPopup &&
            <Modal
            className="modal"
            open={createUserPopup}
            onClose={()=> setCreateUserPopup(false)}
            >
                    <div className="modalPopUp">

                    <CloseOutlined
                    className="popupClose" 
                    onClick={()=> setCreateUserPopup(false)}
                    />
                    <div>

                    <InputBox
                    type="string"
                    placeholder={"Name"}
                    value={name}
                    onChange={(e:any)=>{
                        setName(e.target.value)
                    }}
                    />

                    <InputBox
                    type="string"
                    placeholder={"Email"}
                    value={email}
                    onChange={(e:any)=>{
                        setEmail(e.target.value)
                    }}
                    />

                    </div>

                    <div className="mpuBot">

                    <Button className="mybtn-blue" type="text" onClick={()=>{
                        createNewUSer();
                    
                    }}>
                        Create <RightOutlined />
                    </Button>

                    </div>


                    </div>
            </Modal>
        }
        {
            <ModalBox
            open={error? true: false}
            header="Error !"
            message={error}
            onOk={()=> setError("")}
            />
        }
        {
            // Confirm Box
            <ModalBox
            open={confirm.msg? true: false}
            header="Alert !"
            message={confirm.msg}
            onOk={()=> confirm.type === 1 ? revokeAccess(confirm.id) : confirm.type === 2 ? grantAccess(confirm.id) : shareCredentials(confirm.id) }
            onCancel={ ()=> setConfirm({id:"",type:0,msg:""}) }
            />
        }
        {
            allowView &&
            <>
              {
                loading &&
                <FSLoader>
                    <p className="loaderTxt">Loading...</p>
                </FSLoader>
            }
            <div id="main" style={{paddingTop: "77px"}}>
                <Header />   

                <div style={{
                    padding:"100px 0 50px 0",
                    margin:"0 auto",
                    maxWidth:"800px",
                    width:"90%",
                    }}>

                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",margin:"0 auto 15px auto"}}>
                    <div style={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"flex-start",
                        height:"48px",
                        flex:"1"
                        }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "25px",
                            border: "1px solid #3777BC",
                            overflow: "hidden",
                            height:"48px",
                            width:"100%",
                            maxWidth:"400px",
                            background:"white",
                            padding:"0 5px"
                        }}>
                            <Input 
                            type="search" 
                            style={{
                                flex:"1",
                                height:"100%"
                            }} 
                            placeholder="Search..."
                            value={search}
                            bordered={false}
                            onChange={(e)=> {
                                setSearch(e.target.value ? e.target.value : "")
                            }}
                            />

                            <IconButton size="medium" onClick={ApiCall}>
                                <Search />
                            </IconButton>

                        </div>
                        {
                            loading &&
                            <Spin style={{margin:"0 10px"}} />
                        }
                    </div>
                    <Button type="text" className="histbtn-blue" onClick={()=> setCreateUserPopup(true)}>
                        Create User
                    </Button>
                    </div>
                    
                    <div className="myTableCnt">
                    <table className="myTable">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Password</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.length > 0 ? 
                            data.map((elem,index)=>(
                                <tr key={index}>
                                    <td>
                                        {
                                            elem.name
                                        }
                                    </td>
                                    <td>
                                        {elem.email}
                                    </td>
                                    <td>
                                        <PassToggle 
                                        value={elem.password}
                                        />
                                    </td>
                                    <td>
                                        {
                                            elem.status == 1 &&
                                            <div className="unveri">unverified</div>
                                        }
                                        {
                                            elem.status == 2 &&
                                            <div className="veri">Active</div>
                                        }
                                        {
                                            elem.status == 3 &&
                                            <div className="blocked">Blocked</div>
                                        }
                                        {
                                            elem.status == 4 &&
                                            <div className="deleted">Deleted</div>
                                        }
                                    </td>
                                    <td>
                                        <TripleDotMenu
                                        rowId={elem.id}
                                        items={[
                                            <div 
                                            onClick={()=> elem.status !== 2 ? setConfirm({
                                                id:elem.id,
                                                msg:"Are you sure you want to Grant Access to this person ?",
                                                type:2
                                            }) : setConfirm({
                                                id:elem.id,
                                                msg:"Are you sure you want to Revoke Access of this person ?",
                                                type:1
                                            }) }
                                            >
                                                {elem.status !== 2 ? "Grant Access" : "Revoke Access"}
                                            </div>
                                            // <div
                                            // onClick={()=> setConfirm({
                                            //     id:elem.id,
                                            //     msg:"Are you sure you want to send the credentials to this person?",
                                            //     type:3
                                            // })}
                                            // >
                                            //     Send Credentials
                                            // </div>
                                        ]}
                                        />
                                            
                                    </td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={4} style={{padding:"50px 10px"}}>
                                No data
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                    </div>

                    <div style={{
                        margin:"50px 0 20px 0",
                        display:"flex",
                        justifyContent:"flex-end"
                    }}>
                        <Button 
                        disabled={!prev} 
                        className="histbtn-gray"
                        type="text" 
                        size="large" 
                        style={{margin:"0 5px"}}
                        onClick={()=> {setPage(prev=> prev-1); setPrevLoad(true)} }
                        loading={prevLoad}
                        >
                            <LeftOutlined />
                            Back
                        </Button>
                        <Button 
                        disabled={!next} 
                         className="histbtn-blue bk-blue"
                        type="text" 
                        size="large" 
                        style={{margin:"0 5px"}}
                        onClick={()=> {setPage(prev=> prev+1); setNextLoad(true) }}
                        loading={nextLoad}
                        >
                            Next
                            <RightOutlined />
                        </Button>
                    </div>
                
                </div>

            </div> 


            <footer id="footer">
                    &copy; Cornerstone Consulting Organizations
            </footer>
            </>
        }       
        </AuthenticatedLayout> 
        </>
    )

}

export default Users;